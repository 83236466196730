import React from 'react'

import styled from 'styled-components'
import * as variables from '../utilities/style-variables'
import { Link } from 'gatsby'

const Form = styled.form `
  padding-bottom: 100px;

  &::before {
    content: '';
    display: none;
    position: absolute;
    width: 110%;
    min-height:  800px;
    top: 200px;
    right: -140px;
    bottom: 0;
    left: -200px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
  }

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    &::before {
      display: block;
    }
  } 
  @media (min-width: 1200px) { // X-Large devices (large desktops, 1200px and up)
    &::before {
      width: 100%;
      top: 50px;
      right: 0;
      bottom: 0;
      left: -10em;
    }
  }
  @media (min-width: 1400px) { // XX-Large devices (larger desktops, 1400px and up)
    padding-bottom: 250px;
    
    &::before {
      width: 100%;
      top: 50px;
      right: 0;
      bottom: 0;
      left: -10em;
    }
  }
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    flex-direction: row;
    width: 80%;
    margin: 0 auto;
  }
`

const FormContainer = styled.div`
  margin: 0 auto;
  width: 80%;

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    width: 80%;
  } 

  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    margin: 0 auto;
    padding: 0 20px;
    width: 80%;       
  }
`

const FormGroup = styled.div`
  width: 100%;
  position: relative;
  margin: 20px auto;

  &:hover,
  &:focus-within {
      border-color: ${variables.color_brand_cta};
  }

  &:hover input {
    color: ${variables.color_brand_cta};
  }

  &:focus-within label {
    font-size: 12px;
    padding: 0px;
    top: -25px;
    left: 10px;
    bottom: auto;
  }

  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    max-width: 400px;
  }
`

const Input = styled.input`
  width: 100%;
  padding: 10px 10px;
  font-size: ${variables.font_size__desktop_text};
  border: none;
  background-color: transparent;
  border: 3px solid ${variables.color_black};
  border-radius: ${variables.border_radius};

  &:focus {
    color: inherit !important;
    outline: none;
  }

  &:not(:placeholder-shown) {
    border: 3px solid ${variables.color_brand_cta};
  }

  &:not(:placeholder-shown) ~ label{
    font-size: 12px;
    padding: 0px;
    top: -25px;
    left: 10px;
    bottom: auto;
  }
`

const Label = styled.label`
  position: absolute;
  background-color: transparent;
  padding: 10px 0;
  line-height: 30px;
  top: 0;
  left: 15px;
  bottom: 0;
  color: ${variables.color_black};
  cursor: text;
  transition: all 0.2s ease-in-out;

  span {
    padding-left: 5px;
    font-size: 0.6em;
  }
`

const Textarea = styled.textarea`
  width: 100%;
  height: 190px !important;
  padding: 10px 10px;
  color: ${variables.color_black};
  font-size: ${variables.font_size__desktop_text};
  line-height: 20px;
  border: none;
  background-color: transparent;
  border: 3px solid ${variables.color_black};
  border-radius: ${variables.border_radius};
  resize: none;

  &:focus {
    color: inherit !important;
    outline: none;
  }

  &:not(:placeholder-shown) {
    border: 3px solid ${variables.color_brand_cta};
  }

  &:not(:placeholder-shown) ~ label{
    font-size: 12px;
    padding: 0px;
    top: -25px;
    left: 10px;
    bottom: auto;
    color: ${variables.color_black};
  }
`

const TextareaLabel = styled.label `
  position: absolute;
  background-color: transparent;
  padding: 10px 0;
  line-height: 30px;
  top: 0;
  left: 15px;
  bottom: 0;
  z-index:-1;
  color: ${variables.color_black};
  cursor: text;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${variables.color_brand_secondary};
  }
`

const CheckboxWrapper = styled.div `
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    justify-content: center;
  }
`

const Checkbox = styled.input `
  display: inline-block;
  margin-right: 10px;
  width: 45px;
`

const CheckboxLabel = styled.label `
  display: inline;
  font-size: 0.9em;

  a {
    font-family: ${variables.text_font};
    font-size: inherit;
    text-decoration: underline;
    color: inherit;

    &:hover {
        color: inherit;
    }
  }
`

const SubmitButton = styled.input `
  position: relative;
  display: block;
  width: 80%;
  margin: 20px auto;
  padding: 10px 15px;
  background-color: ${variables.color_brand_cta};
  color: ${variables.color_white};
  border: none;
  border-radius: ${variables.border_radius};
  border-color: ${variables.color_brand_cta};
  text-transform: uppercase;
  transition: all ease-in-out 300ms;

  &:hover {
    background-color: ${variables.color_brand_primary};
    color: ${variables.color_white};
  }

  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    margin-top: 50px;
    width: auto;
  }
`

const ContactForm = () => {

  const handleSubmit=()=>{ 
    window.dataLayer.push({event: "form_submit"});
  }

  return (
    <>
      <Form 
        id="contact-form" 
        method="post" 
        netlify-honeypot="bot-field" 
        data-netlify="true" 
        name="contact" 
        action="/danke"
        onSubmit={handleSubmit}
        >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />

        <FormWrapper>
          <FormContainer>
            <FormGroup>
              <Input name="name" type="text" required id="name" placeholder=" "></Input>
              <Label htmlFor="name" className="control-label">Name</Label>
              
            </FormGroup>

            <FormGroup>
              <Input name="company" type="text" id="company" placeholder=" "></Input>
              <Label htmlFor="company" className="control-label">Firmenname <span>(optional)</span></Label>
            </FormGroup>

            <FormGroup>
              <Input name="email" type="email" required id="email" placeholder=" " pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"></Input>
              <Label htmlFor="email" className="control-label">E-Mail</Label>
            </FormGroup>
          </FormContainer>
          
          <FormContainer>
            <FormGroup>
              <Textarea name="message" id="nachricht" required placeholder=" "></Textarea>
              <TextareaLabel htmlFor="nachricht" className="control-label">Deine Nachricht</TextareaLabel>
            </FormGroup>
          </FormContainer>
        </FormWrapper>

        <CheckboxWrapper>
          <Checkbox type="checkbox" id="form-checkbox" required></Checkbox>
          <CheckboxLabel htmlFor="form-checkbox">
            Ich stimme zu, dass meine Angaben und Daten zur Beantwortung meiner Anfrage elektronisch erhoben und gespeichert werden. 
            Die <Link to="/datenschutz">Datenschutzerklärung</Link> habe ich zur Kenntnis genommen.</CheckboxLabel>
        </CheckboxWrapper>

        <SubmitButton
          type="submit"
          value="Nachricht abschicken"
          form="contact-form"
        ></SubmitButton>
      </Form>
    </>
  )
}

export default ContactForm