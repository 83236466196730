import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/section"
import ContactForm from "../components/contactForm"

const AboutPage = ({ location }) => (
  
<>
  {/* Hero section  */}
  <Layout
    breadcrumb={
      {"Über showcase21": "/ueber-showcase21"}
    }
  >
    <Seo 
      title="Alles über showcase21 | showcase21"
      description="Hier erfährst du alles über die Werte von showcase21."
      lang="de"
      pathname={location.pathname}
      robots="index,follow"
    />
    <Section>
      <div className="row">
        <div className="col-md-12">
          <h1 className="small">Über showcase21</h1>
          <h2 className="main-heading">Einfach. Stark. Passend.</h2>
        </div>
        <div className="col-md-12">
          <p>
          TikTok, Instgram, facebook, Linkedin, Pinterest - und jetzt braucht jede Plattform auch noch spezifische Inhalte und ein strategisches Vorgehen? Dabei ist dein Kerngeschäft ein ganz anderes?<br/><br/>
          Wir erstellen dir Zielgruppen und Plattform spezifische Inhalte und eine Social-Media-Strategie, die zur dir passt. 
          Dabei haben wir dein Budget im Blick und finden eine individuelle Lösung für dein Wachstum. 
          Investiere jetzt deine wichtigste Ressource - deine Zeit - wieder in dein Kerngeschäft. Den Rest übernehmen wir!<br/><br/>
          Mach Social-Media zu deinem Showcase! 
          </p>
        </div>
      </div>
    </Section>

    <Section >
      <div className="row">
        <div className="col">
          <div className="headings">
            <h2>Neugierde geweckt?</h2>
            <p className="subheading">Dann lass uns in Kontakt treten und dein Business mit Leidenschaft weiterentwickeln.</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ContactForm />
        </div>
      </div>
    </Section>

    
  </Layout>
</>
)
export default AboutPage
